/**************************************** Fonts */

// Font-families 
$sohne-breit:'Sohne Breit',
sans-serif;
$space-grotesk: 'Space Grotesk',
sans-serif;
$sf-pro-display: 'SF Pro Display',
sans-serif;

// Font-sizes
$h1: 4.5rem; //72px;
$h2: 3.5rem; //56px;
$h3: 1.875rem; //30px;
$h4: 1.375rem; //22px;
$h5: 1.125rem; //18px;
$body: 1rem; //16px;
$support: 0.813rem; //13px;
$fine: 0.688rem; //11px;

// Font-weight
$font-weight-400: 400; //normal
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700; //bold


// Font-style
$font-style-normal: normal;
$font-style-italic: italic;

/**************************************** others */

// Line-height
$line-height-normal: normal; //default - ~110% to 120%
$line-height-16: 1.6; //recommended - the line height is relative to the font size
$line-height-100: 100%;
$line-height-140: 140%;
$line-height-160: 160%;
$line-height-unset: unset;

// Border-radius
$border-radius-500: 5rem; //80px;
$border-radius-025: 0.25rem; //4px;
$border-radius-0125: 0.125rem; //2px; 

// Border-width
$border-width-0063: 0.063rem; //1px;
$border-width-0125: 0.125rem; //2px;
