
.QRdiv {

  background:
  linear-gradient(to right, #ff0098 4px, transparent 4px) 0 0,
  linear-gradient(to right, #ff0098 4px, transparent 4px) 0 100%,
  linear-gradient(to left, #ff0098 4px, transparent 4px) 100% 0,
  linear-gradient(to left, #ff0098 4px, transparent 4px) 100% 100%,
  linear-gradient(to bottom, #ff0098 4px, transparent 4px) 0 0,
  linear-gradient(to bottom, #ff0098 4px, transparent 4px) 100% 0,
  linear-gradient(to top, #ff0098 4px, transparent 4px) 0 100%,
  linear-gradient(to top, #ff0098 4px, transparent 4px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
  border-radius: 4px;
}

