
.site-layout-content {
  min-height: '100%';
  padding: 10px;
  background: #fff;
}

@media (max-width: 600px) {
  .site-layout-content {
    padding: 10px 4px;
  }
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 10px 0 10px 15px;
}

@media (max-width: 600px) {
  .accountProfile .ant-tag {
    font-size: 8px;
    padding: 0 4px;
  }

  .accountProfile .ant-table-thead > tr > th {
    font-size: 11px;
  }

  .accountProfile .ant-table-tbody > tr > td {
    font-size: 12px;
  }
}