.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: $sohne-breit;
}

.h1,
.h2 {
  line-height: $line-height-100;
}

.h3,
.h4,
.h5,
.body-reg,
.body-sem,
.body-bold,
.fine-reg {
  line-height: $line-height-140;
}

.h6,
.body-reg,
.body-sem,
.body-bold,
.support-reg,
.fine-reg {
  font-family: $space-grotesk;
  font-style: $font-style-normal;
}

.h6,
.body-bold {
  font-weight: $font-weight-700;
}

.body-reg,
.support-reg,
.fine-reg {
  font-weight: $font-weight-400;
}

.body-sem {
  font-weight: $font-weight-600;
}

.h1 {
  font-size: $h1; //72px;
}

.h2 {
  font-size: $h2; //56px;
}

.h3 {
  font-size: $h3; //30px;
}

.h4 {
  font-size: $h4; //22px;
}

.h5,
.h6 {
  font-size: $h5; //18px;
}

.body-reg,
.body-sem,
.body-bold {
  font-size: $body; //16px;
}

.support-reg {
  font-size: $support; //13px
  line-height: $line-height-160;
}

.fine-reg {
  font-size: $fine; //11px
}

.fonts-container .row {
  display: table;
}

.fonts-container .row>[class*='col-'] {
  float: none;
  display: table-cell;
  vertical-align: baseline;
}

.opacity-06 {
  opacity: 0.6
}

// media queries for demo purposes only
@media #{$media-mobile-xxxs} {
  .fonts-container {
    .h1 {
      font-size: 1.5rem;
    }

    .h2 {
      font-size: 1.3rem;
    }

    .h3 {
      font-size: 1.1rem;
    }

    .h4 {
      font-size: 0.9rem;
    }

    .h5,
    .h6 {
      font-size: 0.6rem;
    }

    .body-reg,
    .body-sem,
    .body-bold {
      font-size: 0.5rem;
    }

    .support-reg {
      font-size: 0.4rem;
    }

    .fine-reg {
      font-size: 0.3rem;
    }
  }
}

@media #{$media-mobile-xxs} {
  .fonts-container {
    .h1 {
      font-size: 2rem;
    }

    .h2 {
      font-size: 1.8rem;
    }

    .h3 {
      font-size: 1.6rem;
    }

    .h4 {
      font-size: 1.4rem;
    }

    .h5,
    .h6 {
      font-size: 1.2rem;
    }

    .body-reg,
    .body-sem,
    .body-bold {
      font-size: 0.8rem;
    }

    .support-reg {
      font-size: 0.6rem;
    }

    .fine-reg {
      font-size: 0.5rem;
    }
  }
}

@media #{$media-mobile-xs} {
  .fonts-container {
    .h1 {
      font-size: 3.5rem !important;
    }

    .h2 {
      font-size: 3rem !important;
    }

    .h3 {
      font-size: 1.7rem;
    }

    .h4 {
      font-size: 1.5rem;
    }

    .h5,
    .h6 {
      font-size: 1.3rem;
    }

    .body-reg,
    .body-sem,
    .body-bold {
      font-size: 0.8rem;
    }

    .support-reg {
      font-size: 0.7rem;
    }

    .fine-reg {
      font-size: 0.6rem;
    }
  }
}

@media #{$media-mobile} {
  .fonts-container {
    .h1 {
      font-size: $h1 !important;
    }

    .h2 {
      font-size: $h2 !important;
    }

    .h3 {
      font-size: $h3 !important;
    }

    .h4 {
      font-size: $h4 !important;
    }

    .h5,
    .h6 {
      font-size: $h5 !important;
    }

    .body-reg,
    .body-sem,
    .body-bold {
      font-size: $body !important;
    }

    .support-reg {
      font-size: $support !important;
    }

    .fine-reg {
      font-size: $fine !important;
    }
  }
}
