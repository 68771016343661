$magenta:rgb(255, 0, 152);
$light-purple: rgb(129, 48, 222);
$deep-blue: #3c3cf4;
$primary: $magenta;
$secondary: $light-purple;
$dark: black;
$danger: #bf2222;
$info: #fdfa57;

$grey-background: #EEE; //rgba(238,238,238, 1);
$off-white: #F4F5F7; //rgba(244, 245, 247, 1);
$black-100-grey: #313131; //rgba(49, 49, 49, 1);
$primary-blue: #75a6e1; //rgba(117, 166, 225, 1);
$highlight-blue: #2f2bd5; //rgba(47, 43, 213, 1);
$primary-blue-state: #81b0e4; //rgba(129, 176, 228, 1);
$highlight-blue-state: #444cda; //rgba(68, 76, 218, 1);
$grey-medium-dark: #797A7B; //rgba(121, 122, 123, 1);
$grey-medium-light: #AAAAAA; //rgba(170, 170, 170, 1);
$light-yellow: #FFE58F; //rgba(255,229,143, 1);

$theme-colors: ("info": $info,
  "danger": $danger);

// Tones
$black-012: rgba(0, 0, 0, .12);
$black-020: rgba(0, 0, 0, .2);
$black-045: rgba(0, 0, 0, .45);
$black-050: rgba(0, 0, 0, .5);
$black-085: rgba(0, 0, 0, .85);
$white-020: rgba(255, 255, 255, .2);
$light-yellow-100: rgba(255, 243, 202, 1);
$grey-background-0411: rgba(239, 239, 239, .411);
$orange-050: rgba(255, 54, 0, .5);
$green-050: rgba(23, 173, 48, .5);

// The Bang designs ui components
$magenta-100: #ff0098; //rgba(255, 0, 152, 1);
$magenta-080: rgba($magenta-100, .8); //rgba(255, 0, 152, 0.8);
$magenta-014: rgba($magenta-100, .14);
$magenta-020: rgba($magenta-100, .20);
$magenta-grad-030: rgba(178, 0, 106, 1); //<=>linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #FF0098;
$primary: $magenta-100;
$black-100: #000; //rgba(0, 0, 0, 1);
$white-100: #fff; //rgba(255, 255, 255, 1);
$white-020: rgba($white-100, .2);
$grayscale-090: #1A1A1A; //rgba(26, 26, 26, 1);
$grayscale-075: #404040; //rgba(64, 64, 64, 1);
$grayscale-050: #808080; //rgba(128, 128, 128, 1);
$grayscale-025: #BFBFBF; //rgba(191, 191, 191, 1);
$grayscale-010: #E5E5E5; //rgba(229, 229, 229, 1);
$grayscale-004: #F5F5F5; //rgba(245, 245, 245, 1);
$error-100: #E32D58; //rgba(227, 45, 88, 1);
$green-100: #27AE60; //rgba(39, 174, 96, 1);
$green-80: rgba(#27AE60, 0.8); //rgba(39, 174, 96, 0.8);
$orange-100: #F2994A; //rgba(242, 153, 74, 1);
$blue-100: #3BABC4; //rgba(59, 171, 196, 1)
$blue-80: rgba(#3BABC4, 0.8) //rgba(59, 171, 196, 0.8)