@import "./colours.scss";

$theme-colors: (
    "info": $info,
    "danger": $danger
);

@import "~bootstrap/scss/bootstrap";

svg { vertical-align: baseline; }
