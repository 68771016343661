.currency-formatter {
  font-size: 1.5em; /* Adjust the font size as needed */
}

.whole-part {
  font-size: 2em; /* Adjust the font size for the whole part */
}

.decimal-part {
  font-size: 1em; /* Adjust the font size for the decimal part */
}
