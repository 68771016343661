#navbar-logo {
  @include media-breakpoint-up(lg) {
    width: 100%;
  }
}

#basic-navbar-nav {
  @include media-breakpoint-up(lg) {
    width: 100%;
  }

  * {
    margin-left: auto;
  }
}

#basic-nav-dropdown {
  text-align: end;
  color: white;
}

.navbar-dark .navbar-toggler {
  color: $primary;
  background-color: $primary;
  text-align: end;
  border: 0;
}

.dropdown-menu {
    .dropdown-item {
        font-size: 1.5rem;
        line-height: 22px;

        span > svg {
          vertical-align: baseline;
        }
    }
}

.navbar-nav {
  text-align: right;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  line-height: 22px;
        
  span {
    vertical-align: baseline;
  }
}

.navbar > * {
  align-self: normal;
  min-width: 20%;
}

#navbar-balance > a {
    padding: 11px;
    font-size: 2.5rem;
}

.dropdown-menu {
  a {
    padding-left: 32px;
    padding-right: 38px;
  }
}

.dropdown-menu.show {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.team-screen {
  padding-left: 0;
  padding-right: 0;

  width: 100%;
  height: 100%;

  font-family: $sohne-breit;

  .h1 {
    font-size: $h1;
    font-weight: $font-weight-700;
    line-height: $line-height-160;
  }

  .h2 {
    font-size: $h2;
    font-weight: $font-weight-600;
    line-height: $line-height-160;
  }

  .h3 {
    font-size: $h3;
    font-weight: $font-weight-500;
  }

  .h4 {
    font-size: $h4;
    font-weight: $font-weight-500;
  }

  .h5 {
    font-size: $h5;
    font-weight: $font-weight-400;
  }

  // Body text
  .p {
    font-size: $body;
    line-height: $line-height-16;
    font-weight: $font-weight-400;
  }

  // Support text
  .support-text {
    font-size: $support;
    font-style: $font-style-italic;
  }

  // Fine text
  .fine-text {
    font-size: $fine;
  }

  // Apply other styles like border-radius or border-width if needed
  .rounded-element {
    border-radius: $border-radius-025;
    border-width: $border-width-0063;
    border-style: solid;
  }

  .rounded-img {
    border-radius: $border-radius-025;
  }

}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
    font-family: 'Tahoma-Bold';
    src: url('../fonts/Tahoma Bold.ttf');
  }
  
  .font-tahomaBold {
    font-family: 'Tahoma-Bold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  }
  
  /* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
  @font-face {
    font-family: 'Tahoma';
    src: url('../fonts/Tahoma.ttf');
  }
  
  .font-tahoma {
    font-family: 'Tahoma', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  }
  
  /* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
  @font-face {
    font-family: 'Farisi';
    src: url('../fonts/Farisi.ttf');
  }
  
  .font-farisi {
    font-family: 'Farisi', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  }
  
  .App { /* Base font style */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-size: 15.2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.8500);
    background-color: #EEEEEE;
  }
  
  .App .baseFont * { /* Base font style override for UI framework components */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-size: 15.2px;
  }
  
  body, html, .App .appBg {
    background-color: #EEEEEE !important;
  }
  
  .App .appBg {
    background-color: #EEEEEE;
  }
  
  .App .cardBg {
    background-color: #EEEEEE;
    margin: auto;
  }
  
  .App .baseTextBg { /* Base text color as background */
    background-color: rgba(0, 0, 0, 0.8500);
  }
  
  .App .secondaryTextColor {
    color: rgba(0, 0, 0, 0.5000);
  }
  
  .App .secondaryTextBg {
    background-color: rgba(0, 0, 0, 0.5000);
  }
  
  .App .primaryTextColor {
    color: #75a6e1;
  }
  
  .App .primaryBg {
    background-color: #75a6e1;
  }
  
  .App .highlightTextColor {
    color: #2f2bd5;
  }
  
  .App .highlightBg {
    background-color: #2f2bd5;
  }
  
  @media (display-mode: standalone) {
    .App {
      user-select: none;
    }
  }
  
  /* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
  .mui-btn {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 0;
  }
  .mui-btn--primary {
      background-color: #75a6e1;
  }
  .mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
      background-color: #81b0e4;
  }
  .mui-btn--accent {
      background-color: #2f2bd5;
  }
  .mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
      background-color: #444cda;
  }
  .mui-textfield {  /* Reset UI framework styling that affects layout */
      padding-top: 0;
      margin-bottom: 0;
  }
  .mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
      margin-top: 0;
      margin-bottom: 0;
  }
  .mui-select {  /* Reset UI framework styling that affects layout */
      padding-top: 0;
      margin-bottom: 0;
  }
  .mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
      margin-left: 0;
  }
  .mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
      z-index: auto;
  }
  
  @keyframes fadeIn {
      0%   { opacity: 0.0; }
      100% { opacity: 1.0; }
  }
  @keyframes slideIn_right {
      0%   { transform: translateX(100%); }
      100% { transform: translateX(0); }
  }
  @keyframes slideIn_bottom {
      0%   { transform: translateY(100%); }
      100% { transform: translateY(0); }
  }
  
  /* System font classes */
  
  .App .systemFontRegular {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  }
  .App .systemFontBold {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-weight: 600;
  }
  .App .systemFontItalic {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-style: italic;
  }
  .App .systemFontBoldItalic {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  /* App-wide text styles specified in React Studio */
  
  .App .actionFont {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-size: 14.2px;
    font-weight: 400;
  }
  .App .actionFont * {
    font-size: 14.2px;
  }
  .App .headlineFont {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-size: 19.0px;
    font-weight: 600;
  }
  .App .headlineFont * {
    font-size: 19.0px;
  }
  .App .navBar {
    background-color: #75a6e1;
    color: black;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 50px;
    min-height: 50px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-size: 18px;
  }
  
  .App .navBar a {
    color: black;
  }
  
  .App .navBar .title {
    width: 100%;
    text-align: center;
    padding: 4px;
  }
  
  .App .navBar .backBtn {
    padding: 4px;
    margin-left: 14px;
    cursor: pointer;
  }
  
  .App .navBar > div {
    margin-top: 7px;
  }
  
  @media (min-width: 568px) {
    .App .navBar {
      height: 50px;
      min-height: 50px;
    }
    .App .navBar > div {
      margin-top: 7px;
    }
  }
  @media (min-width: 768px) {
    .App .navBar {
      height: 50px;
      min-height: 50px;
    }
    .App .navBar > div {
      margin-top: 7px;
    }
  }
  @media (min-width: 1024px) {
    .App .navBar {
      height: 50px;
      min-height: 50px;
    }
    .App .navBar > div {
      margin-top: 7px;
    }
  }
  @media (min-width: 320px) {
    .mui-container {
      max-width: 320px;
    }
  }
  @media (min-width: 568px) {
    .mui-container {
      max-width: 568px;
    }
  }
  @media (min-width: 768px) {
    .mui-container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .mui-container {
      max-width: 1024px;
    }
  }
  
  .AppScreen {
    padding-left: 0;
    padding-right: 0;
    
    width: 100%;
    height: 100%;
  }
  
  .App .layoutFlow {
    position: relative;
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .App .layoutFlow:before {  /* Prevent margin collapse */
    content: "";
    display: table;
    clear: both;
  }
  .App .layoutFlow .flowRow {
    position: relative;
    width: 100%;
  }
  
  .App .screenFgContainer {
    /* Items in the foreground group within a screen are placed with fixed positioning */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .App .foreground {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .App ul {
    margin: 0;
    padding: 0;
  }
  .App li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: relative;
  }
  .App .hasNestedComps {
    position: relative;
  }
  .App .hasNestedComps:after {
    content: "";
    display: table;
    clear: both;
  }
  .App .hasNestedComps .layoutFlow {
    position: relative;
  }
  .App .bottomAlignedContent {
    
    bottom: 0;
    width: 100%;
  }
  
  .App .blockInputOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
  }
  
  .App .marker {
    width: 0px;
    height: 0px;
  }
  
  .App .drawer {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  
  .App .drawerOpen {
    transform: translateX(0%);
    transition: 0.3s;
  }
  
  .App .drawerClosedLeft {
    transform: translateX(-100%);
    transition: 0.3s;
  }
  
  .App .drawerClosedRight {
    transform: translateX(100%);
    transition: 0.3s;
  }
  
  .App .drawerContent {
    position: fixed;
    height: 100%;
    overflow: hidden;
  }
  
  .App .drawerContent > * {
    height: 100%;
  }
  
  /* This component has a fixed-size layout */
    .FooterCard {
      width: 100%; /* This component was designed using a width of 300px */
      height: 100%;
      margin-top: 20px;
      background-color: #EEEEEE;
    }
  
    .FooterCard > .layoutFlow > .elTermAndConditionsLinkCopy {
      overflow: hidden;
      position: relative;
      margin-top: 3px;
      width: 160px;
      margin-left: auto;
      margin-right: auto;
    }
    .FooterCard > .layoutFlow > .elTermAndConditionsLinkCopy > * { 
      width: 160px;
    }
  
    .elBetaVersion {
      overflow: hidden;
      width: auto;
      position: relative;
      bottom: -40px;
      right: 0;
      font-size: 7.5px;
      text-align: right; 
      display: inline;
    }
  
    .FooterCard > .layoutFlow > .elSpacer {
      position: relative;
      margin-top: 0px;
      width: 10px;
      margin-left: auto;
      margin-right: auto;
    }
    .FooterCard > .layoutFlow > .elSpacer > * { 
      width: 10px;
      height: 10px;
    }
    
    .FooterCard > .layoutFlow > .flowRow > .elCashlessTippingText {
      overflow: hidden;
      margin-top: 3px;
      margin-bottom: 6px;
      margin-left: auto;
      margin-right: auto;
      width: calc(50.0% - 0px);
    }
    .FooterCard > .layoutFlow > .flowRow > .elCashlessTippingText > * { 
      width: 100%;
    }
    
    .FooterCard .terms-and-conditions {
      margin-top: 20px;
      margin-bottom: 20px;
      text-decoration: underline;
      margin-left: 4px;
      margin-right: 4px;
      color: rgba(0, 0, 0, 0.85)
    }
  
    .poweredBy {
      margin-top: 10px;
    }
  
    .PaymentCard > .layoutFlow .elStrikeLogoImage, .poweredBy .elStrikeLogoImage {
      position: relative;
      margin-top: 0px;
      width: 20px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 20px;
    }
    .PaymentCard > .layoutFlow .elStrikeLogoImage > *, .poweredBy .elStrikeLogoImage > * { 
      width: 20px;
      border-radius: 20px;
    }
  
    .PaymentCard > .layoutFlow .elStrikeLogoImage, .poweredBy .elStrikeFullLogoImage {
      position: relative;
      margin-top: 0px;
      width: 60px;
      margin-left: 5px;
      margin-right: auto;
    }
    .PaymentCard > .layoutFlow .elStrikeLogoImage > *, .poweredBy .elStrikeFullLogoImage > * { 
      width: 120px;
      border-radius: 20px;
    }
    
  
  /* This component has a fixed-size layout */
    .ValueSectionOption {
      min-height: 61px;  /* This component has a fixed size */
      overflow: hidden;
      width: 100%; /* This component was designed using a width of 61px */
      height: 100%;
    }
    .ValueSectionOption > .background > .containerMinHeight {
      min-height: 61px;
    }
  
  
    .ValueSectionOption > .foreground > .state0_elCard {
      position: absolute;
      left: 3px;
      right: 3px;
      top: 0px;
      width: calc(100.0% - 3px - 3px);
      height: 100%;
    }
  
  
    .ValueSectionOption > .foreground > .state0_elTitle {
      overflow: hidden;
      position: absolute;
      font-weight: 500;
      left: 4px;
      right: 4px;
      bottom: 10px;
      width: calc(100.0% - 5px - 4px);
      height: 34px;
    }
  
  /* This component has a fixed-size layout */
    .ValueSectionOption {
      min-height: 61px;  /* This component has a fixed size */
      overflow: hidden;
      width: 100%; /* This component was designed using a width of 61px */
      height: 100%;
    }
    .ValueSectionOption > .background > .containerMinHeight {
      min-height: 61px;
    }
  
  
    .ValueSectionOption > .foreground > .state1_elCardSelected {
      position: absolute;
      left: 3px;
      right: 3px;
      top: 0px;
      width: calc(100.0% - 3px - 3px);
      height: 100%;
    }
  
  
    .ValueSectionOption > .foreground > .state1_elTitle {
      overflow: hidden;
      position: absolute;
      font-weight: 500;
      left: 4px;
      right: 4px;
      bottom: 10px;
      width: calc(100.0% - 5px - 4px);
      height: 34px;
    }
  
  
    .ValueSectionOption > .foreground > .state1_elSelectionMarker {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 32px;
      width: 100.0%;
      height: 2px;
    }
  
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  @media (min-width: 568px) {
    .PaymentSuccessScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  
  .paymentSuccessBanner {
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color: #ff0098;
    color: #fff;
  }
  
  .paymentSuccessBanner .paymentSuccessAmount {
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 18px;
  }
  
  .genericCardBlock {
    border-radius: 4px;
    background-color: #fff;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: center;
    padding: 10px 20px;
    position: relative;
    max-width: 1024px;
  }
  
  .sponsoredCardBlock {
    margin-top: 30px;
  }
  
  .copyableBlock {
    position: relative;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .copyableBlock span {
    position: absolute;
    font-size: 12px;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #797A7B;
    cursor: pointer;
  }
  
  .sponsoredTag {
    position: absolute;
    top: -18px;
    right: 4px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    color: #AAAAAA;
  }
  
  .relativeProfile {
    position: relative;
  }
  
  .relativeProfile .verticallyCentred {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .relativeProfile .elDisplayNameText {
    margin-top: 10px !important;
  }
  
  .promoCopyable {
    background-color: #F4F5F7;
    color: #000;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 10px 40px;
    padding-top: 26px;
    border: 0;
    font-weight: 600;
    cursor: pointer;
  }
  
  .promoCopy {
    width: 80%;
    margin: auto;
  }
  
  @media (max-width: 768px) {
    .promoCopy {
      width: 90%;
    }
  }
  
  .promoImage {
    max-height: 52px;
    margin-bottom: 16px;
    margin-top: 10px;
  }
  
  .promoButton {
    display: block;
    color: #fff;
    text-align: center;
    background-color: #000;
    cursor: pointer;
    pointer-events: auto;
    font-weight: 600;
    font-size: 17px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 10px;
    text-transform: capitalize;
    box-shadow: 0 0 2px rgba(0,0,0,.12),0 2px 2px rgba(0,0,0,.2)
  }
  
  .promoButton:hover {
    color: #fff;
    background-color: #000;
    box-shadow: 0 0 5px rgba(0,0,0,.12),0 5px 5px rgba(0,0,0,.2);
  }
  
  .boltButton {
    background-color: #34D186;
    color: #fff;
    font-weight: 700;
    text-transform: capitalize;
  }
  
  .boltButton:hover {
    background-color: #34D186;
    color: #fff;
    text-transform: capitalize;
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen .layoutFlow .flowRow .elCard {
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .flowRow .elCard > * { 
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  
    
  @media (min-width: 568px) {
    .PaymentSuccessScreen .layoutFlow .flowRow .elCard {
      margin-left: auto;
      margin-right: auto;
    }
  
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen .layoutFlow .flowRow .elCard {
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .flowRow .elCard > * { 
      margin-left: auto;
      margin-right: auto;
    }
  
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen .layoutFlow .flowRow .elCard {
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .flowRow .elCard > * { 
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen .layoutFlow .elImage {
      height: auto;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  
    .PaymentSuccessScreen .layoutFlow .elImage > * {
      margin-left: auto;
      margin-right: auto;
      max-width: 90%;
    }
  
  @media (min-width: 568px) {
    .PaymentSuccessScreen .layoutFlow .elImage {
      height: auto;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen .layoutFlow .elImage {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elImage > * { 
      max-width: 90%;
    }
    
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen .layoutFlow .elImage {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elImage > * { 
      max-width: 90%;
    }
    
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen .layoutFlow .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 238px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elBannerText > * { 
    width: 238px;
    }
    
  @media (min-width: 568px) {
    .PaymentSuccessScreen .layoutFlow .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 238px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elBannerText > * { 
    width: 238px;
    }
    
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen .layoutFlow .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 236px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elBannerText > * { 
    width: 236px;
    }
    
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen .layoutFlow .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 236px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elBannerText > * { 
      width: 236px;
    }
    
  }
  
  .PaymentSuccessScreen .elHeroProfile {
    overflow: hidden;
  }
  
  .PaymentSuccessScreen .elHeroProfile.hasNestedComps > div {
    overflow: hidden;
    position: relative;
  }
  
  
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen .layoutFlow .elHeroProfile {
      position: relative;
      margin-top: 0px;
      width: 220px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elHeroProfile > * { 
    width: 220px;
    }
    
    .PaymentSuccessScreen .layoutFlow .elHeroProfile > .background > .containerMinHeight {
      min-height: 114px;
    }
  
  @media (min-width: 568px) {
    .PaymentSuccessScreen .layoutFlow .elHeroProfile {
      position: relative;
      margin-top: 0px;
      width: 220px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elHeroProfile > * { 
    width: 220px;
    }
    
    .PaymentSuccessScreen .layoutFlow .elHeroProfile > .background > .containerMinHeight {
      min-height: 114px;
    }
  
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen .layoutFlow .elHeroProfile {
      position: relative;
      margin-top: 0px;
      width: 219px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elHeroProfile > * { 
    width: 219px;
    }
    
    .PaymentSuccessScreen .layoutFlow .elHeroProfile > .background > .containerMinHeight {
      min-height: 113px;
    }
  
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen .layoutFlow .elHeroProfile {
      position: relative;
      margin-top: 0px;
      width: 219px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elHeroProfile > * { 
    width: 219px;
    }
    
    .PaymentSuccessScreen .layoutFlow .elHeroProfile > .background > .containerMinHeight {
      min-height: 113px;
    }
  
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen .layoutFlow .elGreenTick {
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
    
  @media (min-width: 568px) {
    .PaymentSuccessScreen .layoutFlow .elGreenTick {
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elGreenTick > * { 
    width: 40px;
    }
    
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen .layoutFlow .elGreenTick {
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen .layoutFlow .elGreenTick {
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen .layoutFlow .elWelldoneText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 238px;
      margin-left: auto;
      font-weight: 600;
      margin-right: auto;
    }
    
  @media (min-width: 568px) {
    .PaymentSuccessScreen .layoutFlow .elWelldoneText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen .layoutFlow .elWelldoneText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen .layoutFlow .elWelldoneText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 236px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elWelldoneText > * { 
    width: 236px;
    }
    
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen .layoutFlow .elAmountTIppedText {
      overflow: hidden;
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
    }
    
  @media (min-width: 568px) {
    .PaymentSuccessScreen .layoutFlow .elAmountTIppedText {
      overflow: hidden;
      position: relative;;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen .layoutFlow .elAmountTIppedText {
      overflow: hidden;
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen .layoutFlow .elAmountTIppedText {
      overflow: hidden;
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  
  .PaymentSuccessScreen .layoutFlow .elAmountFeesTotalText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen .layoutFlow .elFooterContainerCard {
      position: relative;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }
    
  @media (min-width: 568px) {
    .PaymentSuccessScreen .layoutFlow .elFooterContainerCard {
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen .layoutFlow .elFooterContainerCard {
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen .layoutFlow .elFooterContainerCard {
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen .layoutFlow .elButtonCallToAction {
      overflow: hidden;
      margin-top: 10px;
      margin-bottom: 5px;
      margin-left: auto;
      margin-right: auto;
    }
    
  @media (min-width: 568px) {
    .PaymentSuccessScreen .layoutFlow .elButtonCallToAction {
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen .layoutFlow .elButtonCallToAction {
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen .layoutFlow .elButtonCallToAction {
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
    }
    
  }
  
  .PaymentSuccessScreen .elRegisterButton a:hover {
    text-decoration: none;
  }
  
  .PaymentSuccessScreen .elRegisterButton button {
    cursor: pointer;
    border: 0.0px solid transparent;
    border-radius: 4px;
  }
  
  .PaymentSuccessScreen .elRegisterButton button:focus {
    box-shadow: 0 0 5px rgba(23, 173, 48, 0.5000);
    outline: none;
  }
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen .layoutFlow .elRegisterButton {
      line-height: 35px;
      position: relative;
      margin-top: 0px;
      width: 180px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elRegisterButton > * { 
    width: 180px;
    height: 35px;
    }
    
  @media (min-width: 568px) {
    .PaymentSuccessScreen .layoutFlow .elRegisterButton {
      line-height: 35px;
      position: relative;
      margin-top: 0px;
      width: 180px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elRegisterButton > * { 
    width: 180px;
    height: 35px;
    }
    
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen .layoutFlow .elRegisterButton {
      line-height: 35px;
      position: relative;
      margin-top: 0px;
      width: 179px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elRegisterButton > * { 
    width: 179px;
    height: 35px;
    }
    
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen .layoutFlow .elRegisterButton {
      line-height: 35px;
      position: relative;
      margin-top: 0px;
      width: 179px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentSuccessScreen .layoutFlow .elRegisterButton > * { 
    width: 179px;
    height: 35px;
    }
    
  }
  
  .PaymentSuccessScreen .elFooterCard {
    overflow: hidden;
  }
  
  .PaymentSuccessScreen .elFooterCard.hasNestedComps > div {
    overflow: hidden;
  }
  
  
  
  /* Narrowest size for this element's responsive layout */
    .PaymentSuccessScreen .layoutFlow .elFooterCard {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
    }
    
    .PaymentSuccessScreen .layoutFlow .elFooterCard > .background > .containerMinHeight {
      min-height: 50px;
    }
  
  @media (min-width: 568px) {
    .PaymentSuccessScreen .layoutFlow .elFooterCard {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
    }
  
    .PaymentSuccessScreen .layoutFlow .elFooterCard > .background > .containerMinHeight {
      min-height: 50px;
    }
  
  }
  @media (min-width: 768px) {
    .PaymentSuccessScreen .layoutFlow .elFooterCard {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
    }
    
    .PaymentSuccessScreen .layoutFlow .elFooterCard > .background > .containerMinHeight {
      min-height: 50px;
    }
  
  }
  @media (min-width: 1024px) {
    .PaymentSuccessScreen .layoutFlow .elFooterCard {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
    }
    
    .PaymentSuccessScreen .layoutFlow .elFooterCard > .background > .containerMinHeight {
      min-height: 50px;
    }
  
  }
  
  /* This component has a fixed-size layout */
    .RatingPanel {
      width: 100%; /* This component was designed using a width of 219px */
      height: 100%;
    }
  
  
    .RatingPanel > .layoutFlow > .flowRow > .elAvgRatingTitleText {
      overflow: hidden;
      margin-top: 2px;
      margin-left: 4px;
      margin-right: 49.2%;
      width: calc(50.8% - 4px);
    }
    .RatingPanel > .layoutFlow > .flowRow > .elAvgRatingTitleText > * { 
      width: 100%;
    }
  
    .RatingPanel > .layoutFlow > .flowRow > .elAvgRatingText {
      overflow: hidden;
      
      top: 24px;
      margin-left: 4px;
      margin-right: 49.2%;
      width: calc(50.8% - 4px);
    }
    .RatingPanel > .layoutFlow > .flowRow > .elAvgRatingText > * { 
      width: 100%;
    }
  
    .RatingPanel > .layoutFlow > .flowRow > .elCountOfStrikesTitleText {
      overflow: hidden;
      
      top: 2px;
      margin-left: auto;
      margin-right: auto;
      margin-right: 4px;
      width: calc(48.3% - 8px);
    }
    .RatingPanel > .layoutFlow > .flowRow > .elCountOfStrikesTitleText > * { 
      width: 100%;
    }
    
    .RatingPanel > .layoutFlow > .flowRow > .elCountOfStrikesText {
      overflow: hidden;
      top: 24px;
      margin-left: auto;
      margin-right: auto;
      margin-right: 4px;
      width: calc(48.3% - 8px);
    }
    .RatingPanel > .layoutFlow > .flowRow > .elCountOfStrikesText > * { 
      width: 100%;
    }
  
    .RatingPanel > .layoutFlow > .elRating {
      position: relative;
      margin-top: 10px;
      width: 103px;
      margin-left: auto;
      margin-right: auto;
    }
    .RatingPanel > .layoutFlow > .elRating > * { 
    width: 103px;
    height: 20px;
    }
    
  
  /* This component has a fixed-size layout */
    .PaymentCard {
      width: 100%; /* This component was designed using a width of 300px */
      height: 100%;
      padding-bottom: 10px;
    }
  
  .paymentRequestButtonHolder {
    margin-top: 10px;
    padding: 0 30px;
  }
  
  .paymentRequestButtonHolder > form {
    margin-bottom: 10px;
  }
  
  .PaymentCard .elPaywithApplePay button, .PaymentCard .secondary button {
    cursor: pointer;
    border: 0.0px solid transparent;
    border-radius: 4px;
    text-transform: initial;
  }
  
  .PaymentCard .elPaywithApplePay button svg, .PaymentCard .secondary button svg {
    display: block;
    margin: 0 auto;
  }
  
  .styledElPaywithApplePay {
    display: 'block';
    color: '#fff';
    text-align: 'center';
    background-color: '#313131';
    cursor: 'pointer';
    pointer-events: 'auto';
  }
  
  .PaymentCard .secondary {
    background-color: rgba(239, 239, 239, 0.411);
    color: rgba(0, 0, 0, 0.85);
    display: 'block';
    text-align: 'center';
    cursor: 'pointer';
    pointer-events: 'auto';
    text-transform: initial;
    font-weight: 400;
    font-size: 14px;
    min-width: 100% !important;
    box-shadow: 0 0 2px rgba(0,0,0,.12),0 2px 2px rgba(0,0,0,.2)
  }
  
  .PaymentCard .secondary:hover {
    box-shadow: 0 0 5px rgba(0,0,0,.12),0 5px 5px rgba(0,0,0,.2);
  }
  
  .PaymentCard > .layoutFlow > .elPaywithApplePay {
    line-height: 35px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .PaymentCard > .layoutFlow > .elPaywithApplePay > * { 
    width: fit-content;
    padding: 0 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .PaymentCard > .layoutFlow > .elGdprCheckBox {
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: center;
    width: auto;
  }
  .PaymentCard > .layoutFlow > .elGdprCheckBox > * { 
    width: 100%;
  }
  
  .elPaywithApplePay.secondaryPaymentContainer {
    padding: 0 30px;
  }
  
  @media (min-width: 568px) and (max-width: 1040px) { 
    .PaymentCard > .layoutFlow > .elPaywithApplePay.pay {
      margin: 0 5%;
    }
    .PaymentCard > .layoutFlow > .elPaywithApplePay.pay > * {
      width: 100%;
    }
  }
  
  @media (min-width: 376px) and (max-width: 567px) { 
    .PaymentCard > .layoutFlow > .elPaywithApplePay.pay {
      padding: 0 15%;
    }
    .PaymentCard > .layoutFlow > .elPaywithApplePay.pay > * {
      width: 100%;
    }
  }
  
  @media (min-width: 275px) and (max-width: 375px) { 
    .PaymentCard > .layoutFlow > .elPaywithApplePay.pay {
      margin: 0 10%;
    }
    .PaymentCard > .layoutFlow > .elPaywithApplePay.pay > * {
      width: 100%;
    }
  }
  
  @media (max-width: 275px) { 
    .PaymentCard > .layoutFlow > .elPaywithApplePay.pay {
      margin: 0 5%;
    }
    .PaymentCard > .layoutFlow > .elPaywithApplePay.pay > * {
      width: 100%;
    }
  }
  
    @media (min-width: 568px) and (max-width: 1040px) { 
      .elPaywithApplePay.secondaryPaymentContainer {
        padding: 0 5%;
      }
  
      .paymentRequestButtonHolder {
        padding: 0 5%;
      }
    }
  
    @media (min-width: 376px) and (max-width: 567px) { 
      .elPaywithApplePay.secondaryPaymentContainer {
        padding: 0 10%;
      }
  
      .paymentRequestButtonHolder {
        padding: 0 10%;
      }
    }
  
    @media (min-width: 275px) and (max-width: 375px) { 
      .elPaywithApplePay.secondaryPaymentContainer {
        padding: 0 0;
      }
  
      .paymentRequestButtonHolder {
        padding: 0 0;
      }
    }
  
    @media (max-width: 275px) { 
      .elPaywithApplePay.secondaryPaymentContainer {
        padding: 0 5%;
      }
  
      .paymentRequestButtonHolder {
        padding: 0 5%;
      }
    }
  
    .elTermAndConditionsLinkCopy {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    
    .PaymentCard > .layoutFlow > .elTermAndConditionsLinkCopy {
      overflow: hidden;
      position: relative;
      margin-top: 3px;
      width: 160px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentCard > .layoutFlow > .elTermAndConditionsLinkCopy > * { 
    width: 160px;
    }
  
    .PaymentCard > .layoutFlow > .elSpacer {
      position: relative;
      margin-top: 0px;
      width: 10px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentCard > .layoutFlow > .elSpacer > * { 
      width: 10px;
      height: 10px;
    }
    
    .PaymentCard .errorPanel {
      margin: 10px 0;
    }
  
    .PaymentCard .errorText {
      padding-top: 2px;
      color: red;
      font-size: 14px;
      margin-bottom: 2px;
    }
  
  
    .PaymentCard > .layoutFlow > .flowRow > .elCashlessTippingText {
      overflow: hidden;
      
      margin-top: 4px;
      margin-left: 0px;
      margin-right: 50.0%;
      width: calc(50.0% - 0px);
    }
    .PaymentCard > .layoutFlow > .flowRow > .elCashlessTippingText > * { 
      width: 100%;
    }
    
    .ant-checkbox-inner {
      background-color: #fff !important;
      border-color: rgba(255, 0, 152, 1) !important;
    }
  
    .ant-checkbox-inner::after {
      border-color: rgba(255, 0, 152, 1) !important;
    }
  
    .ant-checkbox-checked::after {
      border-color: rgba(255, 0, 152, 1) !important;
    }
  
    .ant-radio-checked .ant-radio-inner {
      border-color: rgba(255, 0, 152, 1) !important;
    }
  
    .ant-radio-inner::after {
      background-color: rgba(255, 0, 152, 1) !important;
    }
  
    .PaymentCard > .layoutFlow .elStrikeLogoImage {
      position: relative;
      margin-top: 0px;
      width: 60px;
      margin-left: auto;
      margin-right: auto;
    }
    .PaymentCard > .layoutFlow .elStrikeLogoImage > * { 
      width: 60px;
    }
    
  
  /* This component has a fixed-size layout */
    .AmountSelectionCard {
      width: 100%; /* This component was designed using a width of 259px */
      height: 100%;
    }
  
  
    .AmountSelectionCard > .layoutFlow > .elSelector {
      position: relative;
      margin-top: 0px;
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .AmountSelectionCard > .layoutFlow > .elSelector > * { 
      width: auto;
      text-align: centre;
    }
    
  
  /* This component has a fixed-size layout */
    .HeroProfile {
      width: 90%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  
    .HeroProfile > .layoutFlow > .elRoundimage {
      position: relative;
      max-width: 122px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .HeroProfile > .layoutFlow > .elRoundimage > * { 
      width: 122px;
      height: 122px;
    }
    
    .QRImageView > .layoutFlow > .elSquareimage {
      position: relative;
      width: 122px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .QRImageView > .layoutFlow > .elSquareimage > * { 
      width: 122px;
      height: 122px;
    }
    
  
    .HeroProfile > .layoutFlow > .elDisplayNameText {
      position: relative;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px;
    }
    .HeroProfile > .layoutFlow > .elDisplayNameText > * { 
      width: 100%;
  
    }
  
  
    .HeroProfile > .layoutFlow > .elBioText {
      position: relative;
      margin-top: 0px;
      width: 100%;
      font-size: 16px;
      margin-left: auto;
      margin-right: auto;
    }
    .HeroProfile > .layoutFlow > .elBioText > * { 
    width: 100%;
    }
    
  
  
  /* Narrowest size for this element's responsive layout */
    .TagDetailScreen > .background > .elBackground {
      width: 100.0%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  @media (min-width: 568px) {
    .TagDetailScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .TagDetailScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    .TagDetailScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  
  .appBg > .layoutFlow {
    height: 100vh;
  }
  
  @media (max-height: 736px) {
    .appBg > .layoutFlow {
      height: auto;
    }
  }
  
  @media (min-height: 1024px) {
    .appBg > .layoutFlow {
      height: auto;
    }
  } 
  
  .receiptHeroImage {
    height: 102px !important;
    width: 102px !important;
  }
  
  @media (max-width: 500px) {
    .receiptHeroImage {
      height: 68px !important;
      width: 68px !important;
    }
  }  
  
  
  /* Narrowest size for this element's responsive layout */
    .TagDetailScreen .layoutFlow > .flowRow > .elCard {
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow > .flowRow > .elCard > * { 
      height: auto;
    }
    
  @media (min-width: 568px) {
    .TagDetailScreen .layoutFlow > .flowRow > .elCard {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }
    
  }
  @media (min-width: 768px) {
    .TagDetailScreen .layoutFlow > .flowRow > .elCard {
      height: auto;
      width: 45%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }
    
  }
  @media (min-width: 1024px) {
    .TagDetailScreen > .background  .layoutFlow > .flowRow > .elCard {
      height: auto;
      width: 360px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .TagDetailScreen .layoutFlow .elImage img {
      max-height: 200px;
      max-width: 90%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      object-fit: contain;
    }
  
    .TagDetailScreen .layoutFlow .elImage {
      max-height: 400px;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background .layoutFlow > .elImage > * { 
      width: 300px;
    }
    
  @media (min-width: 568px) {
    .TagDetailScreen > .background .layoutFlow > .elImage {
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elImage > * { 
      max-height: 80px;
      max-width: 90%;
    }
    
  }
  @media (min-width: 768px) {
    .TagDetailScreen > .background  .layoutFlow > .elImage img {
      max-height: 80px;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elImage > * { 
      max-height: 80px;
      max-width: 90%;
    }
    
  }
  @media (min-width: 1024px) {
    .TagDetailScreen > .background  .layoutFlow > .elImage {
      max-height: 80px;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elImage > * { 
      max-height: 80px;
      max-width: 90%;
    }
    
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .TagDetailScreen > .background  .layoutFlow > .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 238px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elBannerText > * { 
    width: 238px;
    }
    
  @media (min-width: 568px) {
    .TagDetailScreen > .background  .layoutFlow > .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 238px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elBannerText > * { 
    width: 238px;
    }
    
  }
  @media (min-width: 768px) {
    .TagDetailScreen > .background  .layoutFlow > .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 236px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elBannerText > * { 
    width: 236px;
    }
    
  }
  @media (min-width: 1024px) {
    .TagDetailScreen > .background  .layoutFlow > .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 236px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elBannerText > * { 
    width: 236px;
    }
    
  }
  
  .TagDetailScreen .elHeroProfile {
    overflow: hidden;
  }
  
  .TagDetailScreen .elHeroProfile.hasNestedComps > div {
    overflow: hidden;
    position: relative;
  }
  
  
  
  /* Narrowest size for this element's responsive layout */
    .TagDetailScreen > .background  .layoutFlow > .elHeroProfile {
      position: relative;
      margin-top: 0px;
      width: 220px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elHeroProfile > * { 
      width: 220px;
    }
  
  @media (min-width: 568px) {
    .TagDetailScreen > .background  .layoutFlow > .elHeroProfile {
      position: relative;
      margin-top: 0px;
      width: 220px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elHeroProfile > * { 
    width: 220px;
    }
  
  }
  @media (min-width: 768px) {
    .TagDetailScreen > .background  .layoutFlow > .elHeroProfile {
      position: relative;
      margin-top: 0px;
      width: 219px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elHeroProfile > * { 
    width: 219px;
    }
    
    .TagDetailScreen > .background  .layoutFlow > .elHeroProfile > .background > .containerMinHeight {
      min-height: 113px;
    }
  
  }
  @media (min-width: 1024px) {
    .TagDetailScreen > .background  .layoutFlow > .elHeroProfile {
      position: relative;
      margin-top: 0px;
      width: 219px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elHeroProfile > * { 
    width: 219px;
    }
    
    .TagDetailScreen > .background  .layoutFlow > .elHeroProfile > .background > .containerMinHeight {
      min-height: 113px;
    }
  
  }
  
  .TagDetailScreen .elRatingPanel {
    overflow: hidden;
  }
  
  .TagDetailScreen .elRatingPanel.hasNestedComps > div {
    overflow: hidden;
    position: relative;
  }
  
  
  
  /* Narrowest size for this element's responsive layout */
    .TagDetailScreen > .background  .layoutFlow > .elRatingPanel {
      position: relative;
      margin-top: 20px;
      width: 220px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elRatingPanel > * { 
    width: 220px;
    }
    
    .TagDetailScreen > .background  .layoutFlow > .elRatingPanel > .background > .containerMinHeight {
      min-height: 71px;
    }
  
  @media (min-width: 568px) {
    .TagDetailScreen > .background  .layoutFlow > .elRatingPanel {
      position: relative;
      margin-top: 20px;
      width: 220px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elRatingPanel > * { 
    width: 220px;
    }
    
    .TagDetailScreen > .background  .layoutFlow > .elRatingPanel > .background > .containerMinHeight {
      min-height: 71px;
    }
  
  }
  @media (min-width: 768px) {
    .TagDetailScreen > .background  .layoutFlow .elRatingPanel {
      position: relative;
      margin-top: 20px;
      width: 219px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elRatingPanel > * { 
    width: 219px;
    }
    
    .TagDetailScreen > .background  .layoutFlow > .elRatingPanel > .background > .containerMinHeight {
      min-height: 70px;
    }
  
  }
  @media (min-width: 1024px) {
    .TagDetailScreen > .background  .layoutFlow > .elRatingPanel {
      position: relative;
      margin-top: 20px;
      width: 219px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen > .background  .layoutFlow > .elRatingPanel > * { 
    width: 219px;
    }
    
    .TagDetailScreen > .background  .layoutFlow > .elRatingPanel > .background > .containerMinHeight {
      min-height: 70px;
    }
  
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .TagDetailScreen .layoutFlow .elField {
      margin-top: 10px;
      width: 226px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow .elField > * { 
    width: 226px;
    height: 34px;
    }
    
  @media (min-width: 568px) {
    .TagDetailScreen .layoutFlow .elField {
      position: relative;
      margin-top: 10px;
      width: 226px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow .elField > * { 
    width: 226px;
    height: 34px;
    }
    
  }
  @media (min-width: 768px) {
    .TagDetailScreen .layoutFlow > .elField {
      position: relative;
      margin-top: 10px;
      width: 225px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow > .elField > * { 
    width: 225px;
    height: 34px;
    }
    
  }
  @media (min-width: 1024px) {
    .TagDetailScreen .layoutFlow > .elField {
      position: relative;
      margin-top: 10px;
      width: 225px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow > .elField > * { 
    width: 225px;
    height: 34px;
    }
    
  }
  
  .TagDetailScreen .elAmountSelectionCard {
    overflow: hidden;
  }
  
  .TagDetailScreen .elAmountSelectionCard.hasNestedComps > div {
    overflow: hidden;
    position: relative;
  }
  
  
  
  /* Narrowest size for this element's responsive layout */
    .TagDetailScreen .layoutFlow .elAmountSelectionCard {
      position: relative;
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow .elAmountSelectionCard > * { 
      width: auto;
      text-align: center;
    }
    
    .TagDetailScreen .layoutFlow .elAmountSelectionCard > .background > .containerMinHeight {
      min-height: 41px;
    }
  
  @media (min-width: 568px) {
    .TagDetailScreen .layoutFlow .elAmountSelectionCard {
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 258px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow .elAmountSelectionCard > * { 
    width: 258px;
    }
    
    .TagDetailScreen .layoutFlow .elAmountSelectionCard > .background > .containerMinHeight {
      min-height: 41px;
    }
  
  }
  @media (min-width: 768px) {
    .TagDetailScreen .layoutFlow .elAmountSelectionCard {
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 257px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow .elAmountSelectionCard > * { 
      width: 257px;
    }
    
    .TagDetailScreen .layoutFlow .elAmountSelectionCard > .background > .containerMinHeight {
      min-height: 41px;
    }
  
  }
  @media (min-width: 1024px) {
    .TagDetailScreen .layoutFlow .elAmountSelectionCard {
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 257px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow .elAmountSelectionCard > * { 
    width: 257px;
    }
    
    .TagDetailScreen .layoutFlow .elAmountSelectionCard > .background > .containerMinHeight {
      min-height: 41px;
    }
  
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .TagDetailScreen .layoutFlow > .elPaymentContainerCard {
      position: relative;
      margin-top: 24px;
      width: 288px;
      margin-left: auto;
      margin-right: auto;
  
    }
    .TagDetailScreen .layoutFlow > .elPaymentContainerCard > * { 
    width: 280px;
    height: 140px;
    }
    
  @media (min-width: 568px) {
    .TagDetailScreen .layoutFlow > .elPaymentContainerCard {
      position: relative;
      margin-top: 24px;
      width: 288px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow > .elPaymentContainerCard > * { 
    width: 280px;
    height: 140px;
    }
    
  }
  @media (min-width: 768px) {
    .TagDetailScreen .layoutFlow > .elPaymentContainerCard {
      position: relative;
      margin-top: 24px;
      width: 286px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow > .elPaymentContainerCard > * { 
    width: 278px;
    height: 139px;
    }
    
  }
  @media (min-width: 1024px) {
    .TagDetailScreen .layoutFlow > .elPaymentContainerCard {
      position: relative;
      margin-top: 24px;
      width: 286px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow > .elPaymentContainerCard > * { 
    width: 278px;
    height: 139px;
    }
    
  }
  
  .TagDetailScreen .elPaymentCard {
    overflow: hidden;
  }
  
  .TagDetailScreen .elPaymentCard.hasNestedComps > div {
    overflow: hidden;
    position: relative;
  }
  
  .strikeCard {
    background-color: #fff;
    border-radius: 4px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .TagDetailScreen .layoutFlow > .elPaymentCard {
      position: relative;
      margin-top: -130px;
      width: 240px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow > .elPaymentCard > * { 
    width: 240px;
    }
    
    .TagDetailScreen .layoutFlow > .elPaymentCard > .background > .containerMinHeight {
      min-height: 107px;
    }
  
  @media (min-width: 568px) {
    .TagDetailScreen .layoutFlow > .elPaymentCard {
      position: relative;
      margin-top: -130px;
      width: 240px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow > .elPaymentCard > * { 
    width: 240px;
    }
    
    .TagDetailScreen .layoutFlow > .elPaymentCard > .background > .containerMinHeight {
      min-height: 127px;
    }
  
  }
  @media (min-width: 768px) {
    .TagDetailScreen .layoutFlow > .elPaymentCard {
      position: relative;
      margin-top: -129px;
      width: 239px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow > .elPaymentCard > * { 
    width: 239px;
    }
    
    .TagDetailScreen .layoutFlow > .elPaymentCard > .background > .containerMinHeight {
      min-height: 127px;
    }
  
  }
  @media (min-width: 1024px) {
    .TagDetailScreen .layoutFlow > .elPaymentCard {
      position: relative;
      margin-top: -129px;
      width: 239px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagDetailScreen .layoutFlow > .elPaymentCard > * { 
    width: 239px;
    }
    
    .TagDetailScreen .layoutFlow > .elPaymentCard > .background > .containerMinHeight {
      min-height: 127px;
    }
  
  }
  
  /* This component has a fixed-size layout */
    .TagProfileImage {
      width: 100%; /* This component was designed using a width of 196px */
      height: 100%;
    }
  
  
    .TagProfileImage > .layoutFlow > .elRoundimage {
      position: relative;
      margin-top: 10px;
      width: 40px;
      margin-left: auto;
      margin-right: auto;
    }
    .TagProfileImage > .layoutFlow > .elRoundimage > * { 
    width: 40px;
    }
    
  
  
  
    .TagProfileImage > .layoutFlow > .elText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
      width: calc(100.0% - 0px);
    }
    .TagProfileImage > .layoutFlow > .elText > * { 
      width: 100%;
    }
    
  
  /* This component has a fixed-size layout */
    .TagProfileDetails {
      width: 100%; /* This component was designed using a width of 125px */
      height: 100%;
    }
  
  
    .TagProfileDetails > .layoutFlow > .elText {
      overflow: hidden;
      position: relative;
      margin-top: 20px;
      margin-left: 0px;
      margin-right: 0px;
      width: calc(100.0% - 0px);
    }
    .TagProfileDetails > .layoutFlow > .elText > * { 
      width: 100%;
    }
    
  
  
    .TagProfileDetails > .layoutFlow > .elText2 {
      overflow: hidden;
      position: relative;
      margin-top: 10px;
      margin-left: 0px;
      margin-right: 0px;
      width: calc(100.0% - 0px);
    }
    .TagProfileDetails > .layoutFlow > .elText2 > * { 
      width: 100%;
    }
    
  
  /* This component has a fixed-size layout */
    .TagListItemDetail {
      width: 100%; /* This component was designed using a width of 322px */
      height: 100%;
    }
  
    .TagListItemDetail .elColumns > div {
      display: flex;
      flex-direction: row;
      height: auto;  /* This element is in scroll flow */
    }
  
    .TagListItemDetail .elColumns .col0 {
      flex: 50.0;  /* Base weight for this column */
      position: relative;
    }
  
  @media (min-width: 1024px) {
    .TagListItemDetail .elColumns .col0 {
      flex: 50.0;  /* Column width for this screen format */
      flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    }
  
  }
  
  @media (max-width: 1023px) {
    .TagListItemDetail .elColumns .col0 {
      flex: 50.0;  /* Column width for this screen format */
      flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    }
  
  }
  
  @media (max-width: 767px) {
    .TagListItemDetail .elColumns .col0 {
      flex: 50.0;  /* Column width for this screen format */
      flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    }
  
  }
  
  @media (max-width: 567px) {
    .TagListItemDetail .elColumns .col0 {
      flex: 50.0;  /* Column width for this screen format */
      flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    }
  
  }
  
    .TagListItemDetail .elColumns .col1 {
      flex: 50.0;  /* Base weight for this column */
      position: relative;
    }
  
  @media (min-width: 1024px) {
    .TagListItemDetail .elColumns .col1 {
      flex: 50.0;  /* Column width for this screen format */
      flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    }
  
  }
  
  @media (max-width: 1023px) {
    .TagListItemDetail .elColumns .col1 {
      flex: 50.0;  /* Column width for this screen format */
      flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    }
  
  }
  
  @media (max-width: 767px) {
    .TagListItemDetail .elColumns .col1 {
      flex: 50.0;  /* Column width for this screen format */
      flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    }
  
  }
  
  @media (max-width: 567px) {
    .TagListItemDetail .elColumns .col1 {
      flex: 50.0;  /* Column width for this screen format */
      flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    }
  
  }
  
  
  
    .TagListItemDetail > .layoutFlow > .elColumns {
      position: relative;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
      width: calc(100.0% - 0px);
    }
    .TagListItemDetail > .layoutFlow > .elColumns > * { 
      width: 100%;
    }
    
  
  
  /* Narrowest size for this element's responsive layout */
    .N404Screen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  @media (min-width: 568px) {
    .N404Screen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .N404Screen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    .N404Screen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .N404Screen > .layoutFlow > .elText {
      overflow: hidden;
      position: relative;
      margin-top: 130px;
      width: 237px;
      margin-left: auto;
      margin-right: auto;
    }
    .N404Screen > .layoutFlow > .elText > * { 
    width: 237px;
    }
    
  @media (min-width: 568px) {
    .N404Screen > .layoutFlow > .elText {
      overflow: hidden;
      position: relative;
      margin-top: 130px;
      width: 237px;
      margin-left: auto;
      margin-right: auto;
    }
    .N404Screen > .layoutFlow > .elText > * { 
    width: 237px;
    }
    
  }
  @media (min-width: 768px) {
    .N404Screen > .layoutFlow > .elText {
      overflow: hidden;
      position: relative;
      margin-top: 129px;
      width: 236px;
      margin-left: auto;
      margin-right: auto;
    }
    .N404Screen > .layoutFlow > .elText > * { 
    width: 236px;
    }
    
  }
  @media (min-width: 1024px) {
    .N404Screen > .layoutFlow > .elText {
      overflow: hidden;
      position: relative;
      margin-top: 129px;
      width: 236px;
      margin-left: auto;
      margin-right: auto;
    }
    .N404Screen > .layoutFlow > .elText > * { 
    width: 236px;
    }
    
  }
  
  
  .N404Screen > .layoutFlow > .elFooterCard {
    position: relative;
    margin-top: 140px;
    width: 298px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Narrowest size for this element's responsive layout */
    .N404Screen > .layoutFlow > .elImage {
      position: relative;
      margin-top: 40px;
      width: 136px;
      margin-left: auto;
      margin-right: auto;
    }
    .N404Screen > .layoutFlow > .elImage > * { 
    width: 136px;
    }
    
  @media (min-width: 568px) {
    .N404Screen > .layoutFlow > .elImage {
      position: relative;
      margin-top: 40px;
      width: 136px;
      margin-left: auto;
      margin-right: auto;
    }
    .N404Screen > .layoutFlow > .elImage > * { 
    width: 136px;
    }
    
  }
  @media (min-width: 768px) {
    .N404Screen > .layoutFlow > .elImage {
      position: relative;
      margin-top: 40px;
      width: 136px;
      margin-left: auto;
      margin-right: auto;
    }
    .N404Screen > .layoutFlow > .elImage > * { 
    width: 136px;
    }
    
  }
  @media (min-width: 1024px) {
    .N404Screen > .layoutFlow > .elImage {
      position: relative;
      margin-top: 40px;
      width: 136px;
      margin-left: auto;
      margin-right: auto;
    }
    .N404Screen > .layoutFlow > .elImage > * { 
    width: 136px;
    }
    
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .YourTagsScreen > .background > .elBackground {
      
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  @media (min-width: 568px) {
    .YourTagsScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .YourTagsScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    .YourTagsScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  
  .YourTagsScreen .elList li {
    margin-bottom: 5px;
  }
  
  
  
  /* Narrowest size for this element's responsive layout */
    .YourTagsScreen > .layoutFlow > .elList {
      position: relative;
      margin-top: 50px;
      width: 322px;
      margin-left: 0px;
    }
    .YourTagsScreen > .layoutFlow > .elList > * { 
    width: 322px;
    }
    
  @media (min-width: 568px) {
    .YourTagsScreen > .layoutFlow > .elList {
      position: relative;
      margin-top: 50px;
      width: 322px;
      margin-left: 0px;
    }
    .YourTagsScreen > .layoutFlow > .elList > * { 
    width: 322px;
    }
    
  }
  @media (min-width: 768px) {
    .YourTagsScreen > .layoutFlow > .elList {
      position: relative;
      margin-top: 50px;
      width: 320px;
      margin-left: 0px;
    }
    .YourTagsScreen > .layoutFlow > .elList > * { 
    width: 320px;
    }
    
  }
  @media (min-width: 1024px) {
    .YourTagsScreen > .layoutFlow > .elList {
      position: relative;
      margin-top: 50px;
      width: 320px;
      margin-left: 0px;
    }
    .YourTagsScreen > .layoutFlow > .elList > * { 
    width: 320px;
    }
    
  }
  
  .YourTagsScreen .elIconButton {
    cursor: pointer;
    border: 0.0px solid transparent;
    border-radius: 4px;
  }
  
  .YourTagsScreen .elIconButton:focus {
    box-shadow: 0 0 5px rgba(255, 54, 0, 0.5000);
    outline: none;
  }
  
  
  
  /* Narrowest size for this element's responsive layout */
    .YourTagsScreen > .screenFgContainer > .foreground > .elIconButton {
      position: fixed;
      right: 6px;
      top: 3px;
      width: 41px;
      height: 36px;
    }
  @media (min-width: 568px) {
    .YourTagsScreen > .screenFgContainer > .foreground > .elIconButton {
      right: 6px;
      top: 3px;
      width: 41px;
      height: 36px;
    }
  }
  @media (min-width: 768px) {
    .YourTagsScreen > .screenFgContainer > .foreground > .elIconButton {
      right: 6px;
      top: 3px;
      width: 41px;
      height: 36px;
    }
  }
  @media (min-width: 1024px) {
    .YourTagsScreen > .screenFgContainer > .foreground > .elIconButton {
      right: 6px;
      top: 3px;
      width: 41px;
      height: 36px;
    }
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .LoginScreen > .background > .elBackground {
      
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  @media (min-width: 568px) {
    .LoginScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .LoginScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    .LoginScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .LoginScreen > .layoutFlow > .elImage {
      position: relative;
      margin-top: 40px;
      width: 128px;
      margin-left: auto;
      margin-right: auto;
    }
    .LoginScreen > .layoutFlow > .elImage > * { 
    width: 128px;
    }
    
  @media (min-width: 568px) {
    .LoginScreen > .layoutFlow > .elImage {
      position: relative;
      margin-top: 40px;
      width: 128px;
      margin-left: auto;
      margin-right: auto;
    }
    .LoginScreen > .layoutFlow > .elImage > * { 
    width: 128px;
    }
    
  }
  @media (min-width: 768px) {
    .LoginScreen > .layoutFlow > .elImage {
      position: relative;
      margin-top: 40px;
      width: 128px;
      margin-left: auto;
      margin-right: auto;
    }
    .LoginScreen > .layoutFlow > .elImage > * { 
    width: 128px;
    }
    
  }
  @media (min-width: 1024px) {
    .LoginScreen > .layoutFlow > .elImage {
      position: relative;
      margin-top: 40px;
      width: 128px;
      margin-left: auto;
      margin-right: auto;
    }
    .LoginScreen > .layoutFlow > .elImage > * { 
    width: 128px;
    }
    
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .LoginScreen > .layoutFlow > .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 238px;
      margin-left: auto;
      margin-right: auto;
    }
    .LoginScreen > .layoutFlow > .elBannerText > * { 
    width: 238px;
    }
    
  @media (min-width: 568px) {
    .LoginScreen > .layoutFlow > .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 238px;
      margin-left: auto;
      margin-right: auto;
    }
    .LoginScreen > .layoutFlow > .elBannerText > * { 
    width: 238px;
    }
    
  }
  @media (min-width: 768px) {
    .LoginScreen > .layoutFlow > .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 236px;
      margin-left: auto;
      margin-right: auto;
    }
    .LoginScreen > .layoutFlow > .elBannerText > * { 
    width: 236px;
    }
    
  }
  @media (min-width: 1024px) {
    .LoginScreen > .layoutFlow > .elBannerText {
      overflow: hidden;
      position: relative;
      margin-top: 0px;
      width: 236px;
      margin-left: auto;
      margin-right: auto;
    }
    .LoginScreen > .layoutFlow > .elBannerText > * { 
    width: 236px;
    }
    
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .LoginScreen > .layoutFlow > .elFirebaseLogin {
      position: relative;
      margin-top: 40px;
      margin-left: 0px;
      margin-right: 0px;
      width: calc(100.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elFirebaseLogin > * { 
      width: 100%;
    height: 200px;
    }
    
  @media (min-width: 568px) {
    .LoginScreen > .layoutFlow > .elFirebaseLogin {
      position: relative;
      margin-top: 40px;
      margin-left: 0px;
      margin-right: 0px;
      width: calc(100.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elFirebaseLogin > * { 
      width: 100%;
    height: 200px;
    }
    
  }
  @media (min-width: 768px) {
    .LoginScreen > .layoutFlow > .elFirebaseLogin {
      position: relative;
      margin-top: 40px;
      margin-left: 0px;
      margin-right: 0px;
      width: calc(100.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elFirebaseLogin > * { 
      width: 100%;
    height: 199px;
    }
    
  }
  @media (min-width: 1024px) {
    .LoginScreen > .layoutFlow > .elFirebaseLogin {
      position: relative;
      margin-top: 40px;
      margin-left: 0px;
      margin-right: 0px;
      width: calc(100.0% - 0px);
    }
    .LoginScreen > .layoutFlow > .elFirebaseLogin > * { 
      width: 100%;
    height: 199px;
    }
    
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .MaybePaymentScreen > .background > .elBackground {
      
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  @media (min-width: 568px) {
    .MaybePaymentScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .MaybePaymentScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    .MaybePaymentScreen > .background > .elBackground {
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100.0%;
      height: 100%;
    }
  }
  
  
  /* Narrowest size for this element's responsive layout */
    .MaybePaymentScreen > .layoutFlow > .elStripe {
      position: relative;
      margin-top: 40px;
      width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
    .MaybePaymentScreen > .layoutFlow > .elStripe > * { 
    width: 320px;
    height: 163px;
    }
    
  @media (min-width: 568px) {
    .MaybePaymentScreen > .layoutFlow > .elStripe {
      position: relative;
      margin-top: 40px;
      width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
    .MaybePaymentScreen > .layoutFlow > .elStripe > * { 
    width: 320px;
    height: 163px;
    }
    
  }
  @media (min-width: 768px) {
    .MaybePaymentScreen > .layoutFlow > .elStripe {
      position: relative;
      margin-top: 40px;
      width: 318px;
      margin-left: auto;
      margin-right: auto;
    }
    .MaybePaymentScreen > .layoutFlow > .elStripe > * { 
      width: 318px;
      height: 162px;
    }
    
  }
  @media (min-width: 1024px) {
    .MaybePaymentScreen > .layoutFlow > .elStripe {
      position: relative;
      margin-top: 40px;
      width: 318px;
      margin-left: auto;
      margin-right: auto;
    }
    .MaybePaymentScreen > .layoutFlow > .elStripe > * { 
      width: 318px;
      height: 162px;
    }
    
  }
  
  .half-tile {
    width: 50%;
    display: inline-block;
  }
  
  .loading-spinner {
    height: 100%;
    width: fit-content;
    // 80px = loading spinner
    padding-top: calc(50vh - 30px) !important;
    padding-bottom: calc(50vh - 30px) !important;
  }

  .loading-spinner-with-navbar {
    // 30px = 1/2 loading spinner 60px = navbar
    padding-top: calc(50vh - 30px - 60px) !important;
  }
  
  .ant-layout, .ant-layout-footer {
    background-color: #EEEEEE !important;
  }

  .ant-spin {
    color: #ff0098 !important;
  }
  
  .cropper img {
    max-width: 100%;
  }
  
  .centred-action-buttons .ant-list-item-action {
    text-align: center;
  }
  
  .setupRequiredCta {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .setupRequiredCta a {
    text-decoration: underline;
    color: #000;
    text-transform: uppercase;
    font-size: 12px;
  }
  
  .setupRequiredCta a:hover {
    text-decoration: underline;
    color: #000;
  }
  
  /* Ant Layout Overrides: */
  
  .ant-list-vertical .ant-list-item-action > li {
    width: 100%;
  }
  
  .ant-list-vertical .ant-list-item-action > li:first-child {
    padding-left: 16px !important;
  }
  
  @media (min-width: 568px) {
    .ant-list-vertical .ant-list-item-action > li {
      width: auto;
    }
  }
  
  .tipCardContainer {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 20px;
    margin-right: -20px;
    margin-left: -20px;
    padding-left: 20px;
  }
  
  .ant-btn.tip-team-btn {
    background-color: #000;
    color: #fff;
    border-radius: 8px;
    padding: 7px 44px;
    font-size: 18px;
    font-weight: 500;
    height: intrinsic;
    border-color: transparent;
  }
  
  .ant-btn.tip-team-btn:hover {
    background-color: #000;
    color: #fff;
    border-color: transparent;
  }
  
  .tipCard {
    display: inline-block;
    margin-right: 20px;
    width: 100px;
    text-align: center;
    background-color: #fff;
    color: #000;
  }
  
  .tipCard:hover {
    color: #000
  }
  
  .tipCard img {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
  
  .tipCard p:nth-last-of-type(2) {
    padding: 6px 0;
    margin: 0;
  }
  
  .tipCard p:nth-last-of-type(1) {
    background-color: #000;
    color: #F4F5F7;
    border-radius: 0 0 8px 8px;
    padding: 6px 12px;
    margin: 0;
    font-weight: 500;
  }
  
  .memberTipping .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
    border-radius: 4px;
  }
  
  .memberTipping .ant-input-wrapper {
    text-align: left;
  }
  
  .memberTipping .ant-input-group-addon {
    display: none;
  }

  .ant-modal-footer {
    margin-bottom: 48px;
  }

  .ant-list-item-meta-title {
    padding-right: 32px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    .truelayer-icon {
      position: absolute;
      right: 14px;
      width: 32px;
      background-color: $primary;
      border-radius: 8px;
      padding: 2px 6px 4px;
      height: 32px;
    }
  }  

.twitter-picker span div {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

@media(max-width: 576px) { 
  .ant-picker-panels { 
    flex-direction: column;
  } 
}